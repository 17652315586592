<template>
  <v-container fluid>
    <base-material-card
      icon="fas fa-file-invoice"
      title="Reporte general de ventas"
      class="px-5 py-3"
    >
      <v-row>
        <v-col cols="4">
          <v-menu
            v-model="menu_fecha_inicio"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="pagination.fecha_inicial"
                label="Fecha inicial"
                readonly
                v-on="on"
                :error-messages="errors.fecha_inicial"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="pagination.fecha_inicial"
              @input="
                menu_fecha_inicio = false;
                load();
              "
              locale="es-MX"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-menu
            v-model="menu_fecha_final"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="pagination.fecha_final"
                label="Fecha final"
                readonly
                v-on="on"
                :error-messages="errors.fecha_final"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="pagination.fecha_final"
              @input="
                menu_fecha_final = false;
                load();
              "
              locale="es-MX"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="pagination.cliente_id"
            @input="
              loadSucursales();
              load();
            "
            :items="clientes"
            label="Cliente"
            item-value="id"
            item-text="razon_social"
            :error-messages="errors.categoria_id"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="pagination.sucursal_id"
            @input="load"
            :items="sucursales"
            label="Sucursales"
            item-value="id"
            item-text="nombre"
            :error-messages="errors.categoria_id"
            clearable
            multiple
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="pagination.vendedor_id"
            @input="load"
            :items="vendedores"
            label="Vendedor"
            item-value="id"
            item-text="razon_social"
            :error-messages="errors.categoria_id"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="pagination.estatus"
            @input="load"
            label="Estatus"
            :items="ventasEstatus"
            item-value="id"
            item-text="nombre"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Folio"
            v-model="pagination.id"
            @input="() => load()"
            class=""
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <custom-select
            url="/rutas"
            text="nombre"
            label="Ruta"
            @change="
              (value) => {
                pagination.ruta_id = value;
                load();
              }
            "
            :paginate="false"
            :valor="pagination.ruta_id"
            :first-select="true"
            first-text="Todos"
          ></custom-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Folio fiscal"
            v-model="pagination.folio_fiscal"
            @input="() => load()"
            class=""
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" @click="downloadExcel">Descargar</v-btn>
          <v-btn color="primary" @click="downloadConcentradoExcel"
            >Descargar Concentrado</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="ventas"
            :loading="loading"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.id="{ item }">
              {{ item.id.toString().padStart(5, "0") }}
            </template>
            <template v-slot:item.total="{ item }">
              {{ formatoMoneda(item.total) }}
            </template>
            <template v-slot:item.ruta="{ item }">
              {{ item.ruta ? item.ruta.nombre : "NA" }}
            </template>
            <template v-slot:item.acciones="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn fab icon x-small v-on="on" @click="showDetalles(item)">
                    <v-icon :color="item.cancelada ? 'red' : ''"
                      >far fa-file-alt</v-icon
                    >
                  </v-btn>
                </template>
                <span>Movimientos</span>
              </v-tooltip>

              <v-tooltip top v-if="item.visita">
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    icon
                    x-small
                    v-on="on"
                    @click="showMap(item.visita)"
                  >
                    <v-icon>mdi mdi-map-marker</v-icon>
                  </v-btn>
                </template>
                <span>Visita</span>
              </v-tooltip>
            </template>
            <template v-slot:body.append>
              <tr>
                <td colspan="5"></td>
                <td colspan="2" class="font-weight-bold">
                  {{ formatoMoneda(total) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col col="12">
          <v-pagination
            v-model="pagination.page"
            class="my-4"
            :length="pagination.last_page"
            @input="load"
            :total-visible="10"
            color="primary"
          ></v-pagination>
        </v-col>
      </v-row>
    </base-material-card>

    <v-dialog v-model="dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Detalles</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Cantidad</th>
                    <th>Producto</th>
                    <th>Precio</th>
                    <th>Subtotal</th>
                    <th>Descuento</th>
                    <th>Impuestos</th>
                  </tr>
                </thead>
                <tr v-for="descripcion in venta.descripciones">
                  <td>{{ descripcion.cantidad }}</td>
                  <td>{{ descripcion.producto.nombre }}</td>
                  <td>{{ formatoMoneda(descripcion.precio) }}</td>
                  <td>{{ formatoMoneda(descripcion.importe) }}</td>
                  <td>{{ formatoMoneda(descripcion.descuento) }}</td>
                  <td>{{ formatoMoneda(descripcion.importe_impuestos) }}</td>
                </tr>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isMapShow">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Ubicacion del Vendedor</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="isMapShow = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <GmapMap
                :center="marker"
                :zoom="16"
                style="width: 100%; height: 400px"
              >
                <GmapMarker :position="marker" />
              </GmapMap>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import CustomSelect from "@views/dashboard/component/CustomSelect.vue";
import _ from "lodash";

export default {
  components: {
    CustomSelect,
  },
  data: () => ({
    errors: {},
    clientes: [],
    sucursales: [],
    vendedores: [],
    ventas: [],
    ventasEstatus: [
      {
        id: "",
        nombre: "Todos",
      },
      {
        id: "true",
        nombre: "Activas",
      },
      {
        id: "false",
        nombre: "Canceladas",
      },
    ],
    headers: [
      { text: "ID", value: "id" },
      { text: "Fecha", value: "fecha_venta" },
      { text: "Cliente", value: "cliente.razon_social" },
      { text: "Sucursal", value: "sucursal.nombre" },
      { text: "Vendedor", value: "vendedor.razon_social" },
      { text: "Importe", value: "total" },
      { text: "Ruta", value: "ruta" },
      { text: "Acciones", value: "acciones" },
    ],
    pagination: {
      fecha_inicial: null,
      fecha_final: null,
      estatus: "",
      cliente_id: "",
      sucursal_id: "",
      vendedor_id: "",
      ruta_id: "",
      folio: "",
      id: "",
      page: 1,
      last_page: 1,
      folio_fiscal: "",
    },
    menu_fecha_inicio: false,
    menu_fecha_final: false,
    loading: false,
    dialog: false,
    venta: {
      detalles: [],
      cliente: {},
      sucursal: {},
    },
    total: 0,
    isMapShow: false,
    marker: null,
  }),
  mounted() {
    this.setInitialDates();
    this.load();
    this.loadClientes();
    this.loadVendedores();
  },
  methods: {
    showMap({ lat, lng }) {
      this.marker = { lat: Number(lat), lng: Number(lng) };
      this.isMapShow = true;
    },
    load: _.debounce(function () {
      if (this.pagination.id.length == 0 || this.pagination.id.length > 4) {
        this.loading = true;
        this.ventas = [];
        this.$http
          .get("/reportes/ventas/general", {
            params: this.pagination,
          })
          .then((response) => {
            this.ventas = response.data.data;
            this.pagination.last_page = response.data.meta.last_page;
            this.pagination.page = response.data.meta.current_page;
            this.total = response.data.total;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }, 500),
    loadClientes() {
      this.$http.get("/clientes/all").then((response) => {
        this.clientes = response.data;
      });
    },
    loadSucursales() {
      this.pagination.sucursal_id = [];
      this.sucursales = [];
      this.$http
        .get(`/clientes/${this.pagination.cliente_id}/sucursales`)
        .then((response) => {
          this.sucursales = response.data;
        });
    },
    loadVendedores() {
      this.$http.get("/vendedores/all").then((response) => {
        this.vendedores = response.data;
      });
    },
    showDetalles(venta) {
      this.dialog = true;
      this.$http.get(`/ventas/${venta.id}`).then((response) => {
        response.data.descripciones.map((descripcion) => {
          descripcion.importe_impuestos = descripcion.impuestos.length
            ? descripcion.impuestos
                .map((impuesto) => {
                  return impuesto.pivot.importe;
                })
                .reduce((a, b) => {
                  return a + b;
                })
            : 0;
          return descripcion;
        });
        this.venta = response.data;
      });
    },
    setInitialDates() {
      this.pagination.fecha_inicial = moment()
        .startOf("day")
        .format("YYYY-MM-DD");
      this.pagination.fecha_final = moment()
        .startOf("day")
        .format("YYYY-MM-DD");
    },
    downloadExcel() {
      window.location.assign(
        `/reportes/ventas/general/excel?${jQuery.param(this.pagination)}`
      );
    },
    downloadConcentradoExcel() {
      window.location.assign(
        `/reportes/ventas/concentrado/excel?${jQuery.param(this.pagination)}`
      );
    },
  },
};
</script>
